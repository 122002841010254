@import 'tailwindcss/base';
@import 'tailwindcss/components';

/**
 * Custom utilities
 */

.container {
  @apply max-w-full xl:max-w-6xl mx-auto py-6 sm:px-6 flex flex-col;
}

.btn {
  @apply inline-flex items-center px-4 py-2 border-2 border-solid border-transparent text-sm leading-5 font-medium transition-colors duration-150 ease-in-out;
}
.btn-ring {
  @apply focus-visible:ring-4 focus-visible:ring-opacity-50 focus-visible:ring-blue-600
    focus:outline-none;
}

.btn:focus {
  @apply outline-none;
}

.text-link {
  @apply border-b-2 pb-1 border-blue-100 transition-colors hover:border-blue-200;
}

.link {
  @apply text-link;
}

.link::after {
  content: '→';
  display: inline;
  padding-left: 5px;
}

.badge {
  @apply bg-red-500 rounded-full w-2 h-2 absolute top-1 left-1;
}

.select {
  @apply focus:ring rounded-md focus:outline-none;
}

.form-field {
  @apply appearance-none border-gray-300 border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring;
}

.page-width {
  @apply max-w-5xl mx-4 xl:mx-auto py-6;
}

.grid-week {
  grid-template-columns: 50px repeat(7, 1fr);
}

.grid-week-col {
  @apply w-screen-70 sm:w-screen-30 md:w-auto gap-0.5;
  scroll-snap-align: center;
  scroll-snap-stop: normal;
}

.week-scroll {
  @apply overflow-x-scroll;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 20px;
}

.session-grow {
  @apply focus:outline-none
    transform transition-transform duration-200
    focus-visible:scale-105 focus-visible:shadow-xl focus-visible:bg-blue-100
    hover:scale-105 hover:shadow-xl;
}
.round-centre {
  @apply flex items-center justify-center;
}

.h-74 {
  height: 74vh;
}

.span-hour {
  grid-row: auto / span 2;
}

.avatar {
  @apply inline-block h-6 w-6 rounded-full text-white ring-white ring-2;
}

.week-session {
  @apply flex flex-col px-2 py-1 mx-2 relative z-0;
}

.panel-heading {
  @apply font-bold text-blue-800 mb-1;
}

.th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}

.th--col {
  @apply text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}

@import 'tailwindcss/utilities';

/**
 * Custom utilities
 */

@variants focus, hover {
  .z-0 {
    z-index: 0;
  }
  .z-10 {
    z-index: 10;
  }
}

input[type='file']::-webkit-file-upload-button,
input[type='file']::file-selector-button {
  @apply text-white bg-blue-700 hover:bg-blue-600 font-medium text-white text-sm cursor-pointer rounded-md border-none px-2;
  margin-inline-end: 1em;
  height: 100%;
  border-radius: 4px;
  padding: 0 1em;
}
